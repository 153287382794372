<template>
  <div class="box">
    <div class="bgback" v-if="show">
      <van-loading size="68" />
    </div>

    <div class="topcenters" v-if="!show">
      <div class="topcenter">
        <div>
          <i
            class="el-icon-arrow-left"
            @click="backbtn"
            style="font-size: 30px; margin-right: 20px"
          ></i>
        </div>
        <span class="tiptop"
          ><i class="el-icon-warning-outline"></i
          >用户可横向滚动查看更多详情</span
        >
        <div class="user">
          <div class="labelUser">客户姓名：</div>
          <span>{{ srcData.name }}</span>
          <div class="labelUser">联系电话：</div>
          <span>{{ srcData.phone }}</span>
          <div class="labelUser">顺丰单号：</div>
          <span>{{ srcData.orderNoExpress }}</span>
        </div>
        <!--        <table class="tablebox" v-if="wuLiaoOrdershow">
                  <tr class="sellbox">
                    <th colspan="7" class="fb f26">物料明细单</th>
                  </tr>
                  <tr class="sellbox tl padtr">
                    <th colspan="7">
                      <div class="labelth">开单时间：</div>
                      <span>{{ wuLiaoOrder.date }}</span>
                      <div class="labelth">物料单号：</div>
                      <span>{{ wuLiaoOrder.orderNoLaiLiao }}</span>
                    </th>
                  </tr>
                  <tr class="bggray">
                    <th class="indexWidth">序号</th>
                    <th class="styleth">来料毛重</th>
                    <th class="styleth">类别</th>
                    <th class="styleth">熔后克重</th>
                    <th class="styleth">折算材质</th>
                    <th class="styleth">折算比例</th>
                    <th class="styleth">折算重量(g)</th>
                  </tr>
                  <tr v-for="(items, index) in wuLiaoOrder.itemList" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ items.weightRongHou }}</td>
                    <td>{{ items.name }}</td>
                    <td>{{ items.weightRongHou }}</td>
                    <td>
                      {{ items.name.includes("黄金") ? "黄金板料" : items.weight }}
                    </td>
                    <td>{{ items.percentage }}</td>
                    <td>{{ items.weightZeZu }}</td>
                  </tr>
                  <tr>
                    <td colspan="7"></td>
                  </tr>
                  <tr>
                    <td class="bggray">原重量</td>
                    <td>{{ wuLiaoOrder.weightTotal }}</td>
                    <td class="bggray">熔后克重</td>
                    <td>{{ wuLiaoOrder.weightRongHou }}</td>
                    <td class="bggray">折算总重量</td>
                    <td>{{ wuLiaoOrder.weightZeZu }}</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td class="bggray">备注</td>
                    <td colspan="6">
                      <span class="f26">{{ wuLiaoOrder.intro }}</span>
                    </td>
                  </tr>
                </table>-->

        <table class="tablebox" v-if="wuLiaoOrdershow">
          <tr class="sellbox">
            <th colspan="6" class="fb f26">物料明细单</th>
          </tr>
          <tr class="sellbox padtr tl">
            <th colspan="6">
              <div class="labelth">开单时间：</div>
              <span>{{ wuLiaoOrder.date }}</span>
              <div class="labelth">物料单号：</div>
              <span>{{ wuLiaoOrder.orderNoLaiLiao }}</span>
            </th>
          </tr>
          <tr class="bggray">
            <th class="indexWidth">序号</th>
            <th class="styleth">类别</th>
            <th class="styleth">折算材质</th>
            <th class="styleth">熔后克重</th>
            <th class="styleth">折算比例</th>
            <th class="styleth">折足克重</th>
          </tr>
          <tr v-for="(items, index) in wuLiaoOrder.itemList" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ items.name }}</td>
            <td>
              {{ items.name.includes("黄金") ? "黄金板料" : items.weight }}
            </td>
            <td>{{ items.weightRongHou }}</td>
            <td>{{ items.percentage }}</td>
            <td>{{ items.weightZeZu }}</td>
          </tr>
          <tr>
            <td colspan="6"></td>
          </tr>
          <tr>
            <td class="bggray">原重量</td>
            <td>{{ wuLiaoOrder.weightTotal }}</td>
            <td class="bggray">熔后克重</td>
            <td>{{ wuLiaoOrder.weightRongHou }}</td>
            <td class="bggray">折算总重量</td>
            <td>{{ wuLiaoOrder.weightZeZu }}</td>
          </tr>
          <tr>
            <td class="bggray">备注</td>
            <td colspan="5">
              <span class="f26">{{ wuLiaoOrder.intro }}</span>
            </td>
          </tr>
        </table>

        <table class="tablebox" v-if="changeMaterialOrdershow">
          <tr class="sellbox">
            <th colspan="8" class="fb f26">换料结算单</th>
          </tr>
          <tr class="sellbox padtr tl">
            <th colspan="9">
              <div class="labelth">开单时间：</div>
              <span>{{ changeMaterialOrder.date }}</span>
              <div class="labelth">结算单号：</div>
              <span>{{ changeMaterialOrder.orderNo }}</span>
            </th>
          </tr>
          <tr class="bggray">
            <th>序号</th>
            <th>类别</th>
            <th>折足克重</th>
            <th>结算价格</th>
            <th>金额</th>
            <th>状态</th>
            <th>定价渠道</th>
            <th>定价时间</th>
          </tr>

          <tr
            v-for="(items, index) in changeMaterialOrder.itemList"
            :key="index"
          >
            <td>{{ index + 1 }}</td>
            <td>{{ items.name }}</td>
            <td>{{ items.weightZeZu }}</td>
            <td></td>
            <td></td>
            <td>{{ items.status }}</td>
            <td>{{ items.priceKind }}</td>
            <td></td>
          </tr>
          <tr>
            <td colspan="9"></td>
          </tr>
          <tr>
            <td class="bggray">折足总重</td>
            <td>{{ changeMaterialOrder.weightZeZu }}</td>
            <td class="bggray">提纯费</td>
            <td>{{ changeMaterialOrder.moneyTiChun }}</td>
            <td class="bggray">快递费</td>
            <td>{{ changeMaterialOrder.moneyYunFei }}</td>
            <td class="bggray">保险费</td>
            <td>{{ changeMaterialOrder.moneyBaoXiang }}</td>
          </tr>
          <tr>
            <td class="bggray">保管费</td>
            <td>{{ changeMaterialOrder.moneyStorageFee }}</td>
            <td class="bggray">合计金额</td>
            <td colspan="7" class="">
              <span class="f26">{{ changeMaterialOrder.moneyTotal }}</span>
            </td>
          </tr>
          <tr>
            <td class="bggray">备注</td>
            <td colspan="8">
              <span class="f26">{{ changeMaterialOrder.intro }}</span>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { ChangeMaterialDianZi } from "@/utils/api";
export default {
  data() {
    return {
      srcData: null,
      wuLiaoOrder: "",
      wuLiaoOrdershow: false,
      changeMaterialOrder: "",
      changeMaterialOrdershow: false,
      show: true
    };
  },
  async mounted() {
    this.init();
    const oid = this.$route.query.orderid;
    const res = await ChangeMaterialDianZi({ orderId: oid });
    console.log(res);
    this.srcData = res.data;
    if (res.data.wuLiaoOrder) {
      this.wuLiaoOrdershow = true;
      this.wuLiaoOrder = res.data.wuLiaoOrder;
      this.show = false;
    }
    if (res.data.changeMaterialOrderModel) {
      this.changeMaterialOrdershow = true;
      this.changeMaterialOrder = res.data.changeMaterialOrderModel;
      this.show = false;
    }
  },
  methods: {
    init() {
      this.forceLandscapeScreenHandle();

      // 这里监控
      this.onWindowSizeChanged();
    },
    forceLandscapeScreenHandle() {
      const body = document.getElementsByTagName("body")[0];
      const html = document.getElementsByTagName("html")[0];
      const width = html.clientWidth;
      const height = html.clientHeight;
      const max = width > height ? width : height;
      const min = width > height ? height : width;
      body.style.width = max + "px";
      body.style.height = min + "px";
    },
    forceLandscapeScreenHandle1() {
      const body = document.getElementsByTagName("body")[0];
      const html = document.getElementsByTagName("html")[0];
      const width = html.clientWidth;
      const height = html.clientHeight;
      const max = width > height ? width : height;
      const min = width > height ? height : width;
      body.style.width = min + "px";
      body.style.height = max + "px";
    },

    onWindowSizeChanged() {
      window.addEventListener("resize", this.forceLandscapeScreen);
    },
    backbtn() {
      this.forceLandscapeScreenHandle1();
      this.$router.push({
        name: "changeMaterial"
      });
    }
  }
};
</script>

<style lang="scss" scoped="true">
::v-deep {
  .van-loading {
    position: absolute;
    top: 40%;
    left: 45%;
    z-index: 999;
  }
}
.box {
  width: 100%;
  height: 100%;
}
.indexWidth {
  width: 100px;
}
.styleth {
  width: 150px;
}
.tablebox {
  text-align: center;
}
.tl {
  text-align: left;
}
.bggray {
  background: #ececec;
}
.bgback {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  background-color: #232323;
}
.w900 {
  width: 620px;
}
.topcenter {
  padding: 0px 0 30px;
  background: #fff;
  position: relative;
  z-index: 2;
}
.user {
  margin: 10px 0 10px 10px;
  .labelUser {
    font-size: 26px;
    display: inline-block;
  }
  span {
    font-size: 26px;
    display: inline-block;
    margin-right: 30px;
  }
}
.tiptop {
  color: #bcbcbc;
  padding: 15px;
  font-size: 22px;
  display: block;
}
table {
  font-size: 26px;
  width: 100%;
  margin: 0 auto 50px;
  tr {
    th {
      padding: 5px;
      // background: #ececec;
      border: 1px solid #ccc;
    }
    td {
      padding: 10px;
      border: 1px solid #ccc;
    }
  }
  .sellbox {
    th {
      font-weight: normal;
      padding: 15px 0 !important;
    }
    span {
      display: inline-block;
      margin-right: 20px;
    }
  }
  .labelth {
    font-size: 26px;
    display: inline-block;
  }
  .fb {
    font-weight: bold !important;
  }
  .padtr {
    th {
      padding: 15px !important;
    }
  }
}
.f26 {
  font-size: 26px;
}

html {
  /*用于 获取 屏幕的可视宽高*/
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  /*让 body 初始 width 和 height 就 等于 页面可视区域的 宽高*/
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
@media screen and (orientation: portrait) {
  /*竖屏样式*/
  .topcenters {
    transform-origin: 0 0;
    transform: rotateZ(90deg) translateY(-100%);
  }
}
@media screen and (orientation: portrait) {
  /*竖屏样式*/
  .topcenters {
    transform-origin: 0 0;
    transform: rotateZ(90deg) translateY(-100%);
  }
}
@media screen and (min-height: 840px) {
  .topcenters {
    width: 844px;
  }
}
@media screen and (min-height: 667px) {
  .topcenters {
    width: 667px;
  }
}
@media screen and (min-height: 896px) {
  .topcenters {
    width: 896px;
  }
}
</style>
